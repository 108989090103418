<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    v-model="selected"
    :menu-props="{ offsetY: true, overflowY: true, closeOnContentClick: true }"
    :items="items"
    item-text="text"
    item-value="value"
  >
  </v-select>
</template>

<script>
  import disabilityLevels from "./disabilityLevels";

  export default {
    inheritAttrs: false,
    name: "DisabilityLevelPicker",
    props: {
      value: [String, Array]
    },
    data: () => ({
      items: disabilityLevels,
      selected: null
    }),
    watch: {
      value: {
        handler(v) {
          this.selected = v;
        },
        immediate: true
      }
    }
  };
</script>

<style scoped></style>
