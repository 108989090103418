import { i18n } from "@/plugins/i18n";

export default [
  {
    text: i18n.t("disability_level.first_level"),
    value: "FIRST_LEVEL"
  },
  {
    text: i18n.t("disability_level.second_level"),
    value: "SECOND_LEVEL"
  },
  {
    text: i18n.t("disability_level.third_level"),
    value: "THIRD_LEVEL"
  }
];
